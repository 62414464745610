//wordpress反映時にはずす import $ from "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "lazysizes";

/*ハンバーガーメニュー*/
const ham = $("#js-hamburger");
const nav = $("#js-nav");
ham.on("click", function () {
  //ハンバーガーメニューをクリックしたら
  ham.toggleClass("active"); // ハンバーガーメニューにactiveクラスを付け外し
  nav.toggleClass("active"); // ナビゲーションメニューにactiveクラスを付け外し
  // $("body").toggleClass("fixed"); // 背景固定用
});

/*TOP FVスライダー*/
$(function ($) {
  $(".fv_slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 5000,
    autoplay: true,
    fade: true,
    dots: false,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    swipe: false,
    swipeToSlide: false,
  });
});

/*TOP solutionスライダー*/
$(function ($) {
  $(".solution_slider").slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 800,
    autoplaySpeed: 4000,
    // autoplay: true,
    dots: false,
    arrows: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    swipe: true,
    swipeToSlide: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});

/*download スライダー*/
function checkBreakPoint() {
  const w = $(window).width();
  if (w <= 998) {
    // スマホ向け（998px以下のとき）
    $(".download_slider")
      .not(".slick-initialized")
      .slick({
        //スライドさせる
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        // autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
  } else {
    // PC向け
    $(".download_slider.slick-initialized").slick("unslick");
  }

  /*blog スライダー*/
  if (w <= 998) {
    // スマホ向け（998px以下のとき）
    $(".blog_slider")
      .not(".slick-initialized")
      .slick({
        //スライドさせる
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        // autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
  } else {
    // PC向け
    $(".blog_slider.slick-initialized").slick("unslick");
  }
}
// ウインドウがリサイズする度にチェック
$(window).resize(function () {
  checkBreakPoint();
});
// 初回チェック
checkBreakPoint();

/*スムーススクロール*/
$(function ($) {
  const headerHeight = 70;

  $("a[href^='#']:not(a.noscroll)").click(function () {
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? "body" : href);
    const position = target.offset().top - headerHeight;
    $("html, body").animate(
      {
        scrollTop: position,
      },
      500,
      "swing"
    );
    return false;
  });
});

/*アコーディオン*/
$(function () {
  //クリックで動く
  $(".accordion > dt").click(function () {
    $(this).toggleClass("active");
    $(this).next("dd").slideToggle();
  });
});

/*トップへ戻るボタン*/
$(function () {
  const pagetop = $("#page_top");
  pagetop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      /*100スクロール後に表示*/
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      500
    ); /*戻る速さ*/
    return false;
  });
});

/*ヘッダースクロールでシャドウ*/
$(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 70) {
      /*70スクロール後に表示*/
      $(".h_top").addClass("active");
    } else {
      $(".h_top").removeClass("active");
    }
  });
});

/*固定バナー*/
$(".fix_bnr .close").on("click", function () {
  //×押したら
  $(".fix_bnr").addClass("active");
});

/*トップのreason画像切り替え*/
$(function () {
  $(".top_reason_list").hover(function () {
    $(".top_reason_photo").removeClass("active");
    $(this).children(".top_reason_photo").addClass("active");
  });
});

/*お問い合わせ個人法人切り替え*/
/*$(function () {
  //最初は個人の必須を非活性化
  $("#form_individual").hide();
  if (document.getElementById("form_individual")) {
    const nobtn01 = document.getElementById("form_individual").querySelectorAll(":required");
    const nobtn02 = document.getElementById("form_individual").querySelectorAll("input");
    const nobtn03 = document.getElementById("form_individual").querySelectorAll("select");
    const nobtn04 = document.getElementById("form_individual").querySelectorAll("textarea");
    for (let i in nobtn01) {
      if (nobtn01.hasOwnProperty(i)) {
        nobtn01[i].setAttribute("disabled", true);
      }
    }
    for (let i in nobtn02) {
      if (nobtn02.hasOwnProperty(i)) {
        nobtn02[i].setAttribute("disabled", true);
      }
    }
    for (let i in nobtn03) {
      if (nobtn03.hasOwnProperty(i)) {
        nobtn03[i].setAttribute("disabled", true);
      }
    }
    for (let i in nobtn04) {
      if (nobtn04.hasOwnProperty(i)) {
        nobtn04[i].setAttribute("disabled", true);
      }
    }

    $("#tab li:first label").addClass("selected");

    $("#tab li input").change(function () {
      if (!$(this).parent("label").hasClass("selected")) {
        $("#tab li label.selected").removeClass("selected");
        $(this).parent("label").addClass("selected");
        $(".form_block>div").hide().filter($(this).attr("value")).fadeIn();

        //ランプの切り替え
        const thisattr = $(this).attr("value");
        const thisclass = thisattr.replace("#", "");
        $(".select_lamp").removeClass().addClass("select_lamp").addClass(thisclass);

        //両方とも必須の非活性化
        const offbtn01 = document.getElementById("form_corporate").querySelectorAll(":required");
        const offbtn02 = document.getElementById("form_corporate").querySelectorAll("input");
        const offbtn03 = document.getElementById("form_corporate").querySelectorAll("select");
        const offbtn04 = document.getElementById("form_corporate").querySelectorAll("textarea");
        for (let i in offbtn01) {
          if (offbtn01.hasOwnProperty(i)) {
            offbtn01[i].setAttribute("disabled", true);
          }
        }
        for (let i in offbtn02) {
          if (offbtn02.hasOwnProperty(i)) {
            offbtn02[i].setAttribute("disabled", true);
          }
        }
        for (let i in offbtn03) {
          if (offbtn03.hasOwnProperty(i)) {
            offbtn03[i].setAttribute("disabled", true);
          }
        }
        for (let i in offbtn04) {
          if (offbtn04.hasOwnProperty(i)) {
            offbtn04[i].setAttribute("disabled", true);
          }
        }

        for (let i in nobtn01) {
          if (nobtn01.hasOwnProperty(i)) {
            nobtn01[i].setAttribute("disabled", true);
          }
        }
        for (let i in nobtn02) {
          if (nobtn02.hasOwnProperty(i)) {
            nobtn02[i].setAttribute("disabled", true);
          }
        }
        for (let i in nobtn03) {
          if (nobtn03.hasOwnProperty(i)) {
            nobtn03[i].setAttribute("disabled", true);
          }
        }
        for (let i in nobtn04) {
          if (nobtn04.hasOwnProperty(i)) {
            nobtn04[i].setAttribute("disabled", true);
          }
        }

        //選んでいる方だけ必須の活性化
        const onbtn01 = document.getElementById(thisclass).querySelectorAll(":required");
        const onbtn02 = document.getElementById(thisclass).querySelectorAll("input");
        const onbtn03 = document.getElementById(thisclass).querySelectorAll("select");
        const onbtn04 = document.getElementById(thisclass).querySelectorAll("textarea");

        for (let i in onbtn01) {
          if (onbtn01.hasOwnProperty(i)) {
            onbtn01[i].removeAttribute("disabled", true);
          }
        }
        for (let i in onbtn02) {
          if (onbtn02.hasOwnProperty(i)) {
            onbtn02[i].removeAttribute("disabled", true);
          }
        }
        for (let i in onbtn03) {
          if (onbtn03.hasOwnProperty(i)) {
            onbtn03[i].removeAttribute("disabled", true);
          }
        }
        for (let i in onbtn04) {
          if (onbtn04.hasOwnProperty(i)) {
            onbtn04[i].removeAttribute("disabled", true);
          }
        }
      }
      return false;
    });
  }
});*/

// お問い合わせ個人法人切り替え
$(function () {
  $(window).bind("load", function () {
    if (document.URL.match(/\/inquiry\//)) {
      //お問い合わせ入力画面でのみ実行
      if ($("#form_individual .error")[0] || document.URL.match(/#individual/)) {
        //個人用フォームにエラー、もしくはURLに個人用IDがあれば個人用を表示
        $("#form_corporate").hide();
        $("#tab li:last a").addClass("selected");
        $(".select_lamp").removeClass().addClass("select_lamp").addClass("form_individual");
      } else {
        $("#form_individual").hide();
        $("#tab li:first a").addClass("selected");
      }
    }
  });
  $("#tab li a").click(function () {
    if (!$(this).hasClass("selected")) {
      $("#tab li a.selected").removeClass("selected");
      $(this).addClass("selected");
      $(".form_block>div").hide().filter($(this).attr("href")).fadeIn();

      //ランプの切り替え
      const thisattr = $(this).attr("href");
      const thisclass = thisattr.replace("#", "");
      $(".select_lamp").removeClass().addClass("select_lamp").addClass(thisclass);
    }
    return false;
  });
});

// お問い合わせエラー時クラス追加
$(function () {
  $(".form_list span.error").prev().addClass("error");
});

// お問い合わせ確認画面幅変更
$(function () {
  $(window).bind("load", function () {
    if (document.URL.match(/confirm/)) {
      $(".form_block").css("max-width", "1000px");
    }
  });
});

// 郵便番号用
$(function () {
  $('input[name="postcode"]').keyup(function (e) {
    AjaxZip3.zip2addr("postcode", "", "prefectures", "address");
  });
  $('input[name="postcode2"]').keyup(function (e) {
    AjaxZip3.zip2addr("postcode2", "", "prefectures2", "address2");
  });
});

//youtube強制再生
const canYouTubePlay = document.getElementById("pause") && document.getElementById("play");

if (canYouTubePlay) {
  (function (win, doc) {
    const tag = doc.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = doc.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    //iframeタグとプレイヤーを作成します
    let playerPC;
    let playerSP;

    function onYouTubeIframeAPIReady() {
      playerPC = new YT.Player("js-main-movie_pc", {
        //js-main-movieはHTMLのid属性の値と同じ
        videoId: "OxGuPxtaCb8", //YouTubeの動画ID
        // height: "339",
        // width: "602",
        playerVars: {
          controls: 0,
          showinfo: 0,
          autoplay: 1,
          disablekb: 1,
          enablejsapi: 1,
          iv_load_policy: 3,
          playsinline: 1,
          rel: 0,
          autohide: 0,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
      playerSP = new YT.Player("js-main-movie_sp", {
        //js-main-movieはHTMLのid属性の値と同じ
        videoId: "FQ-L5b-wW1s", //YouTubeの動画ID
        // height: "339",
        // width: "602",
        playerVars: {
          controls: 0,
          showinfo: 0,
          autoplay: 1,
          disablekb: 1,
          enablejsapi: 1,
          iv_load_policy: 3,
          playsinline: 1,
          rel: 0,
          autohide: 0,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    }

    //YouTube APIは↓の関数を呼び出す
    function onPlayerReady(event) {
      event.target.mute();
      event.target.playVideo();
    }

    //プレイヤーの状態が変化するとYouTube APIは↓の関数を呼び出します
    //プレイヤーが終了するとまた動画の再生をします
    function onPlayerStateChange(event) {
      const ytStatus = event.target.getPlayerState();
      if (ytStatus == YT.PlayerState.ENDED) {
        playerPC.mute();
        playerSP.mute();
        playerPC.playVideo();
        playerSP.playVideo();
      }
    }

    //動画の停止ボタン
    const pause = document.getElementById("pause");
    pause.addEventListener("click", function () {
      playerPC.pauseVideo();
      playerSP.pauseVideo();
    });

    //動画の再生ボタン
    const play = document.getElementById("play");
    play.addEventListener("click", function () {
      playerPC.playVideo();
      playerSP.playVideo();
    });

    // export
    win.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
  })(window, document);
}

/*チーム派遣の他サービスの違い*/
$(function () {
  //クリックで動く
  $("#Difference_btn01").click(function () {
    $("#Difference_list .box").removeClass("active");
    $(this).addClass("active");
    $("#Difference_list .list .box:nth-child(2)").addClass("active");
  });
  $("#Difference_btn02").click(function () {
    $("#Difference_list .box").removeClass("active");
    $(this).addClass("active");
    $("#Difference_list .list .box:nth-child(3)").addClass("active");
    $("#Difference_list .list .box:nth-child(1)").addClass("active");
  });
  $("#Difference_btn03").click(function () {
    $("#Difference_list .box").removeClass("active");
    $(this).addClass("active");
    $("#Difference_list .list .box:nth-child(4)").addClass("active");
  });
});

/*派遣ジャーナル固定バナーの閉じるボタン*/
$(function () {
  //クリックで動く
  $(".blog_fix_bnr .close").click(function () {
    $(".blog_fix_bnr").addClass("active");
  });
});
